@font-face {
  font-family: "Diodrum";
  src: local("Diodrum"),
    url("./assets/fonts/Indian\ Type\ Foundry\ -\ Diodrum\ Arabic\ Medium.otf") format("opentype");
}

* {
  font-family: Diodrum !important;
}

.ant-tooltip-inner {
  background-color: #fff !important;
}

.ant-table-wrapper .ant-radio-group.ant-radio-group-outline {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ant-table-wrapper .ant-radio-group.ant-radio-group-outline span {
  white-space: nowrap;
}

.ant-table {
  overflow: scroll;
}

.ant-spin.ant-spin-spinning {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.ant-spin-nested-loading>div>.ant-spin {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

@media(max-width:767px) {
  .fifty_wrapper {
    width: 100% !important;
  }
}

.times {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.times li {
  position: relative;
}

.times li .dots {
  margin: 0px 2px;
  padding-inline: 2px;
}

.times li:nth-child(1)::before {
  position: absolute;
  content: "d";
  top: -100%;
  left: -8px;
  width: 100%;
}

.times li:nth-child(2)::before {
  position: absolute;
  content: "h";
  top: -100%;
  left: -8px;
  width: 100%;
}

.times li:nth-child(3)::before {
  position: absolute;
  content: "m";
  top: -100%;
  left: 0;
  width: 100%;
}

.info-container>div>span .times {
  margin-top: 40px;
}

.times_container {
  align-items: center;
}

.info-container>div>span .times li:nth-child(2)::before,
.info-container>div>span .times li:nth-child(1)::before {
  text-align: center;
}

div.filter-container {
  display: flex;
  align-items: center;
  color: #1890ff;
}